<template>
  <div>
    <el-card>
      <div class="flexRow" style="justify-content: space-between">
        <div class="titlefont">
          {{$route.query.type==0?'支付结算毛利明细':'发货结算毛利明细'}}
        </div>
        <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnH8uVvL9q2a9B2mdMwRx73g" 
			    :underline="false" target="_blank" type="primary" class="link">毛利项目明细表说明></el-link>
      </div>
      <div style="margin:20px 0px">
        <div class="flexRow" style="margin:10px 0px">
          <div class="keyFont">业务发生时间：</div>
          <el-date-picker v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期"
           value-format="yyyy-MM-dd" format="yyyy-MM-dd" end-placeholder="结束日期" :clearable="false"> </el-date-picker>
        </div>
        <div class="flexRow" style="margin:10px 0px">
          <div class="keyFont">关键字：</div>
          <el-select v-model="KeywordsType" style="width:130px;margin-right:10px">
            <el-option v-for="(v,i) in KeywordsTypelist" :key="i" :value="v.value" :label="v.label"></el-option>
          </el-select>
          <el-input v-model="Keywords" style="width:200px" :placeholder="KeywordsTypelist.find(v=>v.value==KeywordsType).label"></el-input>
        </div>
        <div class="flexRow" style="margin:10px 0px;flex-wrap:wrap">
          <div class="flexRow" style="margin-right:10px">
            <div class="keyFont">业务类型：</div>
            <el-select v-model="BusinessType">
              <el-option v-for="(v,i) in BusinessTypelist" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div class="flexRow" style="margin-right:10px">
            <div class="keyFont" style="width:auto">毛利项目：</div>
            <el-cascader v-model="profittype" :options="options"></el-cascader>
          </div>
          <div class="flexRow" style="margin-right:10px">
            <div class="keyFont" style="width:auto">配送方式：</div>
            <el-select v-model="SendType">
              <el-option v-for="(v,i) in SendTypelist" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <el-button type="primary" style="margin-right:10px" @click="currentPage=1,getlist()">查询</el-button>
          <buttonPermissions :datas="'profitdetailexport'">
            <el-button @click="exportlist">导出</el-button>
          </buttonPermissions>
        </div>
        <div style="margin-top:10px">
          <el-table :data="table" v-loading="tableloading">
            <el-table-column prop="AddTime" label="业务发生时间" min-width="150">
              <template slot="header">
                <div class="flexRow">
                  <span>业务发生时间</span>
                  <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">
                      <div v-if="$route.query.type==0">
                        <div>业务类型为「商品销售」时，即订单支付成功时间</div>
                        <div>业务类型为「商品退款」时，即商品退款成功时间</div>
                      </div>
                      <div v-else>
                        <div>业务类型为「商品发货」时，即商品发货成功时间</div>
                        <div>业务类型为「商品退款」时，即商品退款成功时间</div>
                      </div>
                    </div>
                    <i class="el-icon-question" style="margin:-4px 0px 0px 5px;"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="BusinessTypeDisplay" label="业务类型"></el-table-column>
            <el-table-column prop="" label="源单号" min-width="150">
              <template slot="header">
                <div class="flexRow">
                  <span>源单号</span>
                  <el-tooltip class="item" effect="dark" content="订单编号" placement="top-start">
                    <i class="el-icon-question" style="margin:-4px 0px 0px 5px;"></i>
                  </el-tooltip>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div style="color:#f56c6c">[{{scope.row.SendTypeDisplay}}]</div>
                  <div>{{scope.row.OrderNo}}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="BusinessNo" label="业务单号" min-width="150">
              <template slot="header">
                <div class="flexRow">
                  <span>业务单号</span>
                  <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">
                      <div>业务类型为「商品销售」时，即订单编号</div>
                      <div>业务类型为「商品退款」时，即退款单号</div>
                    </div>
                    <i class="el-icon-question" style="margin:-4px 0px 0px 5px;"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="ItemTypeDisplay" label="毛利项目"></el-table-column>
            <el-table-column prop="" label="数量">
              <template slot="header">
                <div class="flexRow">
                  <span>数量</span>
                  <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">
                      <div v-if="$route.query.type==0">
                        <div>业务类型为「商品销售」时，即商品购买数量</div>
                        <div>业务类型为「商品退款」时，即商品退款数量</div>
                      </div>
                      <div v-else>
                        <div>业务类型为「商品销售」时，数量=商品购买数量-商品发货前退款成功数量</div>
                        <div>业务类型为「商品退款」时，即商品发货后，退款成功数量</div>
                      </div>
                    </div>
                    <i class="el-icon-question" style="margin:-4px 0px 0px 5px;"></i>
                  </el-tooltip>
                </div>
              </template>
              <template slot-scope="scope">
                {{scope.row.ProductCount?scope.row.ProductCount:'--'}}
              </template>
            </el-table-column>
            <el-table-column prop="BusinessMoney" label="金额">
              <template slot="header">
                <div class="flexRow">
                  <span>金额</span>
                  <el-tooltip class="item" effect="dark" placement="top-start">
                    <div slot="content">
                      <div v-if="$route.query.type==0">
                        <div>商品销售收入金额：即商品实付金额</div>
                        <div>商品销售成本金额：=商品购买数量*单个商品成本价</div>
                        <div>订单运费金额：订单支付的运费或配送费，到店自提订单运费为0</div>
                        <div>推手收益支出：=商品购买成功时产生的收益</div>
                        <div>员工提成支出：=商品购买成功时产生的提成</div>
                        <div>推手收益退回：商品发货前，退款成功，扣回的收益</div>
                        <div>员工提成退回：商品发货前，退款成功，扣回的提成</div>
                      </div>
                      <div v-else>
                        <div>商品发货收入金额：=商品实付金额-商品发货前退款成功金额</div>
                        <div>商品发货成本金额：=（商品购买数量-商品发货前退款成功数量）*单个商品成本价</div>
                        <div>订单运费金额：订单支付的运费或配送费，到店自提订单运费为0；订单拆包裹发货，运费仅记录1条毛利项目</div>
                        <div>推手收益支出：=商品购买成功时产生的收益-商品发货前退回的收益</div>
                        <div>员工提成支出：=商品购买成功时产生的提成-商品发货前退回的提成</div>
                        <div>推手收益退回：商品发货后，订单确认收货前，商品退款成功，扣回的收益</div>
                        <div>员工提成退回：商品发货后，订单确认收货前，商品退款成功，扣回的提成</div>
                      </div>
                    </div>
                    <i class="el-icon-question" style="margin:-4px 0px 0px 5px;"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="商品名称" min-width="300">
              <template slot-scope="scope">
                <div>
                  <span style="color:#f56c6c" v-if="scope.row.ProductType==1">[组合]</span>
                  <span style="color:#f56c6c" v-if="scope.row.IsGift">[赠品]</span>
                  <span>{{scope.row.ProductName}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="商品编码">
              <template slot-scope="scope">
                {{scope.row.ProductNo?scope.row.ProductNo:'--'}}
              </template>
            </el-table-column>
            <el-table-column prop="" label="规格">
              <template slot-scope="scope">
                {{scope.row.SpecDisplay?scope.row.SpecDisplay:'--'}}
              </template>
            </el-table-column>
            <el-table-column prop="" label="规格条形码">
              <template slot-scope="scope">
                {{scope.row.BarCode?scope.row.BarCode:'--'}}
              </template>
            </el-table-column>
            <el-table-column prop="" label="店员">
              <template slot-scope="scope">
                <div v-if="scope.row.EmployeeName||scope.row.EmployeePhone">
                  <div>{{scope.row.EmployeeName}}</div>
                  <div>{{scope.row.EmployeePhone}}</div>
                </div>
                <div v-else>--</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top:10px;text-align:right" v-if="total">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import {
  grossprofitdetailindexv2
} from "@/api/goods"
import config from "@/config"
export default {
  components:{
    buttonPermissions
  },
  data () {
    return {
      exporturl:config.EXPORT_URL,
      time:[],
      KeywordsType:1,
      KeywordsTypelist:[
        {value:1,label:'订单编号'},
        {value:2,label:'退款单号'},
        {value:3,label:'商品名称'},
        {value:4,label:'商品编码'},
      ],
      Keywords:'',
      BusinessType:null,
      BusinessTypelist:[
        {value:null,label:'全部'},
        {value:1,label:'商品销售'},
        {value:2,label:'商品退款'},
      ],
      profittype:[null],
      options:[
        {value:null,label:'全部'},
        {value:1,label:'收入项',children:[
          {value:1,label:'商品销售收入'},
          {value:2,label:'商品发货前退款'},
        ]},
        {value:2,label:'成本项',children:[
          {value:3,label:'商品销售成本'},
          {value:4,label:'商品发货前退回成本'},
        ]},
        {value:3,label:'销售费用项',children:[
          {value:5,label:'订单运费'},
          {value:6,label:'发货前运费退款'},
          {value:7,label:'推手收益支出'},
          {value:8,label:'发货前推手收益退回'},
          {value:9,label:'员工提成支出'},
          {value:10,label:'发货前员工提成退回'},
        ]},
      ],
      SendType:null,
      SendTypelist:[
        {value:null,label:'全部'},
        {value:0,label:'快递发货'},
        {value:1,label:'到店自提'},
        {value:2,label:'同城配送'},
      ],

      table:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:null,
    }
  },
  created(){
    this.info()
    this.gettime()
    this.getlist()
  },
  methods: {
    info(){
      if(this.$route.query.type==0) return 
      this.options = [
        {value:null,label:'全部'},
        {value:1,label:'收入项',children:[
          {value:1,label:'商品发货收入'},
          {value:2,label:'商品发货后退款'},
        ]},
        {value:2,label:'成本项',children:[
          {value:3,label:'商品发货成本'},
        ]},
        {value:3,label:'销售费用项',children:[
          {value:5,label:'订单运费'},
          {value:6,label:'发货后运费退款'},
          {value:7,label:'推手收益支出'},
          {value:8,label:'发货后推手收益退回'},
          {value:9,label:'员工提成支出'},
          {value:10,label:'发货前员工提成退回'},
        ]},
      ]
    },
    gettime(){
      let date = new Date()
      // let lastdate = new Date(new Date().getTime()-1000*60*60*24)

      let year = date.getFullYear()
      let month = date.getMonth()+1>10?date.getMonth()+1:'0'+(date.getMonth()+1)
      let day = date.getDate()>10?date.getDate():'0'+date.getDate()

      // let oldyear = lastdate.getFullYear()
      // let oldmonth = lastdate.getMonth()+1>10?lastdate.getMonth()+1:'0'+(lastdate.getMonth()+1)
      // let oldday = lastdate.getDate()>10?lastdate.getDate():'0'+lastdate.getDate()

      this.time = [[year,month,day].join('-'),[year,month,day].join('-')]
    },
    nexttime(time){
      if(!time) return ''
      
      let date = new Date(new Date(time).getTime()+1000*60*60*24)

      let year = date.getFullYear()
      let month = date.getMonth()+1>10?date.getMonth()+1:'0'+(date.getMonth()+1)
      let day = date.getDate()>10?date.getDate():'0'+date.getDate()

      return [year,month,day].join('-') + ' 00:00:00'
    },
    checktime(time){
      if(!time.length) return false
      let time1 = time[0]
      let time2 = time[1]
      if((new Date(time2).getTime()-new Date(time1).getTime())/1000/60/60/24 > 92){
        this.$message.error('业务发生时间最多选择92天')
        return false
      }
      return true
    },
    async getlist(){
      if(!this.checktime(this.time)){
        return 
      }
      try{
        this.tableloading = true
        let data = {
          StartTime:this.time[0],
          EndTime:this.nexttime(this.time[1]),
          KeywordsType:this.KeywordsType,
          Keywords:this.Keywords,
          BusinessType:this.BusinessType,
          ItemType:this.profittype[this.profittype.length-1],
          SendType:this.SendType,
          ShopId:this.$route.query.Id,
          PerformanceType:this.$route.query.type,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        }
        let res = await grossprofitdetailindexv2(data)
        if(res.IsSuccess){
          this.table = res.Result.Results
          this.total = res.Result.Total
          // console.log(this.table)
        }
      }finally{
        this.tableloading = false
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.getlist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.getlist()
    },
    exportlist(){
      let url = this.exporturl + '/pc/shop-performance/gross-profit-detail-index/v2' + 
                '?StartTime=' + (this.time[0]?this.time[0]:'') + 
                '&EndTime=' + (this.nexttime(this.time[1])?this.nexttime(this.time[1]):'') + 
                '&KeywordsType=' + (this.KeywordsType?this.KeywordsType:'') + 
                '&Keywords=' + (this.Keywords?this.Keywords:'') + 
                '&BusinessType=' + (this.BusinessType?this.BusinessType:'') + 
                '&ItemType=' + (this.profittype[this.profittype.length-1]?this.profittype[this.profittype.length-1]:'') + 
                '&SendType=' + (this.SendType==null?'':this.SendType) + 
                '&ShopId=' + this.$route.query.Id + 
                '&PerformanceType=' + this.$route.query.type

      window.open(url)
    }
  }
}
</script>

<style lang="less" scoped>
.titlefont{
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}
.flexRow{
  display: flex;
  align-items: center;
  // justify-content: space-between;
}
.keyFont{
  width: 100PX;
  text-align: right;
  color: #606266;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  flex-shrink: 0;
}
</style>